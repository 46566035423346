import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import clsx from 'clsx';
import {isNil} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LOAN_DATE_FORMAT} from '../../../../constants/format-templates';
import {LoanDetailsLabel} from '../../../../constants/loan-details-labels';
import {RoutePaths} from '../../../../routes/routes.paths';
import usePost from '../../../../shared/hooks/use-post.hook';
import {areNotEmpty, PhaseValidationResult} from '../../../../shared/model/phase-validation-result.model';
import {ApiHelper} from '../../../../utils/api-helper';
import {CashHelper} from '../../../../utils/cash-helper';
import {StringHelper} from '../../../../utils/string-helper';
import {TransHelper} from '../../../../utils/trans-helper';
import {useGuardedHistory} from '../../../router/GuardedHistory';
import {AppSnackbarContext} from '../../../shared/app-snackbar-provider/AppSnackbarProvider';
import ChannelIcons from '../../../shared/channel-icons/ChannelIcons';
import DiscardChangesButton from '../../../shared/discard-changes-button/DiscardChangesButton';
import {LoanDetail} from '../../../shared/loan-detail/LoanDetail';
import SaveAndExitButton from '../../../shared/save-and-exit-button/SaveAndExitButton';
import ProductStatusChip from '../../../shared/status-chip/ProductStatusChip';
import TopBar from '../../../shared/top-bar/TopBar';
import ConfigErrorResult from '../../shared/config-error-result/ConfigErrorResult';
import {LoanConfigurationContext} from '../LoanConfiguration';
import styles from './LoanConfigurationTopBar.module.scss';

const {addDays} = StringHelper;
const {getPrefixedTrans, getLoanDetailsLabel} = TransHelper;
const PrefixTrans = getPrefixedTrans('TOP_BAR');

export default function LoanConfigurationTopBar(): React.ReactElement {

  const {t} = useTranslation();
  const history = useGuardedHistory();
  const {showErrorMessage, showSuccessMessage} = useContext(AppSnackbarContext);
  const {
    isLoanPublished,
    isProcessUpdating,
    process,
    configurationChanged,
    setSaveAndExitClicked,
    isSaveDisabled
  } = useContext(
    LoanConfigurationContext);
  const {id, status, channels, loanProduct, modifiedBy, modifiedOn} = process;
  const handlePublish = usePost<void, void>(ApiHelper.getLoanPublishUrl(id));
  const validateProcess = usePost<PhaseValidationResult[], void>(ApiHelper.getLoanValidateUrl(id));
  const [isPublishing, setIsPublishing] = useState(false);

  const publishLoan = async (): Promise<void> => {
    setIsPublishing(true);
    handlePublish()
      .then(() => history.push(RoutePaths.LOAN_CONFIGURATIONS))
      .catch((error) => {
        setIsPublishing(false);
        showErrorMessage(error.message ?? t('LOAN_CONFIGURATIONS.PUBLISH_FAILED.ERROR_MESSAGE'));
      });
  };

  const isValid = async (): Promise<boolean | void> => {
    return validateProcess()
      .then(result => {
        if (areNotEmpty(result)) {
          showErrorMessage(<ConfigErrorResult results={result} />);
          return false;
        }

        showSuccessMessage(t('LOAN_CONFIGURATIONS.CONFIGURATION_VALID'));
        return true;
      })
      .catch(error => showErrorMessage(error.message));
  };

  const saveAndExit = async (): Promise<void> => {
    setSaveAndExitClicked(true);
  };

  const Channels = !isNil(channels) ? <ChannelIcons channels={channels ?? []} /> : '-';

  const PublishLoanButton =
    <NxButton className={styles.button} loaded={!isPublishing} variant={NxButtonVariant.OUTLINED} onClick={publishLoan}>
      <PrefixTrans>PUBLISH_LOAN</PrefixTrans>
    </NxButton>;

  const {CHANNEL, STATUS, MODIFIED_BY, PRODUCT_NAME, MIN_AMOUNT, MAX_AMOUNT, MIN_TERM, MAX_TERM, LAST_EDIT}
    = LoanDetailsLabel;

  const {name, minAmount, maxAmount, minTerm, maxTerm} = loanProduct ?? {};
  const modifiedOnDate = modifiedOn?.format(LOAN_DATE_FORMAT) ?? '-';

  return (
    <TopBar
      details={<>
        <LoanDetail title={getLoanDetailsLabel(PRODUCT_NAME)} content={name} />
        <LoanDetail title={getLoanDetailsLabel(STATUS)} content={<ProductStatusChip status={status} />} />
        <LoanDetail title={getLoanDetailsLabel(CHANNEL)} content={Channels} desktopOnly />
        <LoanDetail title={getLoanDetailsLabel(LAST_EDIT)} content={modifiedOnDate} desktopOnly />
        <LoanDetail title={getLoanDetailsLabel(MODIFIED_BY)} content={modifiedBy ?? '-'} desktopOnly />
      </>}
      actionButtons={<>
        {
          isLoanPublished
            ? <DiscardChangesButton className={styles.button} path={RoutePaths.LOAN_CONFIGURATIONS} />
            : PublishLoanButton
        }
        <SaveAndExitButton className={styles.button}
                           configurationChanged={configurationChanged}
                           showConfirmationPopup={isLoanPublished}
                           savingInProgress={isProcessUpdating}
                           onSave={saveAndExit}
                           redirectPath={RoutePaths.LOAN_CONFIGURATIONS}
                           isSaveDisabled={isSaveDisabled} />
      </>}
      moreDetails={<>
        <LoanDetail title={getLoanDetailsLabel(MIN_TERM)} content={minTerm ? addDays(minTerm, t) : '-'} />
        <LoanDetail title={getLoanDetailsLabel(MAX_TERM)} content={maxTerm ? addDays(maxTerm, t) : '-'} />
        <LoanDetail title={getLoanDetailsLabel(MIN_AMOUNT)}
                    content={minAmount ? CashHelper.addCurrency(minAmount) : '-'} />
        <LoanDetail title={getLoanDetailsLabel(MAX_AMOUNT)}
                    content={maxAmount ? CashHelper.addCurrency(maxAmount) : '-'} />
      </>}
      moreActionButtons={<>
        <NxButton variant={NxButtonVariant.OUTLINED} onClick={isValid}>
          <PrefixTrans>VALIDATE_CONFIGURATION</PrefixTrans>
        </NxButton>
      </>}
    />
  );
}
