import {NxLoader} from '@nextbank/ui-components';
import {Form, Formik, FormikProps} from 'formik';
import isNil from 'lodash/isNil';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {boolean, object} from 'yup';
import {PhaseName} from '../../../../../constants/api-urls';
import {UrlParams} from '../../../../../routes/routes.model';
import useFileUpload from '../../../../../shared/hooks/use-file-upload.hook';
import useGet from '../../../../../shared/hooks/use-get.hook';
import usePost from '../../../../../shared/hooks/use-post.hook';
import {fromPhaseTO, Phase, PhaseTO} from '../../../../../shared/model/phase.model';
import {PrintType} from '../../../../../shared/model/print.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {optionalValidationParams} from '../../../../../utils/optional-validation-form-utils';
import {validateAndSubmitForm} from '../../../../../utils/step-form-utils/validation-schema/validation-schema-utils';
import {AppSnackbarContext} from '../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import FormGroup from '../../../../shared/form-column/FormGroup';
import {LoanApplicationContext} from '../../LoanApplication';
import styles from '../customer-data/custom-fields/CustomFields.module.scss';
import {getCheckValuesValidationSchema} from '../shared/custom-checks/check-values-schema-utils';
import {handleCustomFieldValues} from '../shared/custom-checks/check-values-utils';
import CheckFields from '../shared/custom-checks/CheckFields';
import useCustomCheckInitValues from '../shared/custom-checks/use-custom-check-init-values';
import useCustomCheckDictionaries from '../shared/custom-checks/use-custom-checks-dictionaries-query.hook';
import LoanApplicationStep from '../shared/loan-application-step/LoanApplicationStep';
import {StepPayloadType} from '../shared/step-payload.model';
import {CustomPhaseFormFields, CustomPhasePayload} from './custom-phase-form.model';
import {hasEditableFields} from '../shared/custom-checks/edit-permissions-utils';
import {SecurityContext} from '../../../../../App';

const {getPhaseConfigurationUrl, getPhaseSaveUrl} = ApiHelper;

export default function CustomPhase(): React.ReactElement {

  const {t} = useTranslation();
  const {uploadFile} = useFileUpload();
  const {applicationId} = useParams<UrlParams>();
  const {showErrorMessage} = useContext(AppSnackbarContext);
  const {application, processId, setOpenedPhaseId, setHasEditableField} = useContext(LoanApplicationContext);
  const {userData} = useContext(SecurityContext);
  const {phaseId} = useParams<UrlParams>();
  const phaseUrl = getPhaseConfigurationUrl(processId, PhaseName.CUSTOM);
  const fetchCustomPhase = useGet<PhaseTO>(`${phaseUrl}/${phaseId}`);
  const [config, setConfig] = useState<Phase>();
  const checkInitValues = useCustomCheckInitValues(config, application?.checkValues);
  const saveStep = usePost<void, CustomPhasePayload>(getPhaseSaveUrl(applicationId));
  const {dictionaries, areDictionariesLoaded} = useCustomCheckDictionaries(config?.checkGroups);
  const [isCustomPhaseLoading, setIsCustomPhaseLoading] = useState(true);

  useEffect(() => {
    setIsCustomPhaseLoading(true);
    fetchCustomPhase()
      .then(configTO => {
        setConfig(fromPhaseTO(configTO));
        setIsCustomPhaseLoading(false);
      })
      .catch(error => showErrorMessage(error.message));
    setOpenedPhaseId(parseInt(phaseId));
  }, [phaseId]);

  useEffect(() => {
    setHasEditableField(
      hasEditableFields(config, userData, application)
    );
  }, [config]);

  const submit = async (values: CustomPhaseFormFields): Promise<void> => {

    const checkValues = await Promise.all(handleCustomFieldValues(values.checkValues, uploadFile));

    const stepPayload: CustomPhasePayload = {
      type: StepPayloadType.CUSTOM_PHASE_PAYLOAD,
      checkValues
    };

    return saveStep(stepPayload, null, optionalValidationParams(values.validate));
  };

  const CustomPhaseForm = (
    {values, submitForm, dirty, validateForm, setFieldValue}: FormikProps<CustomPhaseFormFields>
  ): ReactElement => {

    const handleSave = async (validate: boolean): Promise<void> =>
      validateAndSubmitForm<CustomPhaseFormFields>(values, validate, submitForm, validateForm, setFieldValue, t);

    return (
      <LoanApplicationStep handleSave={handleSave}
                           phaseId={config?.id}
                           dataChanged={dirty}
                           approvalRules={config?.approvalRules}
                           printType={PrintType.CUSTOM_PHASE}>
        <Form>
          {
            config?.checkGroups.map((checkGroup, groupIndex) =>
              <FormGroup className={styles.inputs} header={checkGroup.name} key={groupIndex}>
                <CheckFields checkValues={values.checkValues}
                             checks={checkGroup.checks}
                             dictionaries={dictionaries} />
              </FormGroup>
            )
          }
        </Form>
      </LoanApplicationStep>
    );
  };

  const validationSchema = object({
    validate: boolean(),
    checkValues: getCheckValuesValidationSchema(t)
  });

  return isNil(config) || !areDictionariesLoaded || isNil(checkInitValues) || isCustomPhaseLoading
    ? <NxLoader />
    : <Formik<CustomPhaseFormFields>
      onSubmit={submit}
      enableReinitialize // when changing between different custom phases initValues changes
      validateOnChange={false}
      validationSchema={validationSchema}
      initialValues={{checkValues: checkInitValues}}>
      {CustomPhaseForm}
    </Formik>;
}
