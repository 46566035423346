import React, {ReactElement, useState} from 'react';
import {TableCheckItem} from '../../../../../../shared/model/check.model';
import {NxButton, NxCashInput, NxInput, NxNumberInput, NxPercentageInput, NxSelect} from '@nextbank/ui-components';
import {Trans} from 'react-i18next';
import clsx from 'clsx';
import styles from './TableCheckList.module.scss';
import {useTranslation} from 'react-i18next';
import {FieldType} from '../../../../../../shared/model/field.model';
import {NumberHelper} from '../../../../../../utils/number-helper';
import {getInputValue} from '../../../../../../utils/input-utils';
import isNil from 'lodash/isNil';
import BinButton from '../../../../../shared/bin-button/BinButton';

const COMMON_FIELD_TYPES_TRANS = 'COMMON.FIELD_TYPES';
const COMMON_FIELDS_TRANS = 'COMMON.FIELDS';

interface Props {
  items?: TableCheckItem[];
  onSave: (items: TableCheckItem[]) => void;
}

const TableCheckList = ({items, onSave}: Props): ReactElement => {
  const {t} = useTranslation();
  const [updatedItems, setUpdatedItems] = useState<TableCheckItem[]>(items || []);
  const [name, setName] = useState<string>();
  const [fieldType, setFieldType] = useState<FieldType | undefined>(FieldType.STRING);
  const [value, setValue] = useState<string>();

  const fieldTypeOptions = [
    {value: FieldType.CASH, label: t(`${COMMON_FIELD_TYPES_TRANS}.CASH`)},
    {value: FieldType.INTEGER, label: t(`${COMMON_FIELD_TYPES_TRANS}.INTEGER`)},
    {value: FieldType.PERCENTAGE, label: t(`${COMMON_FIELD_TYPES_TRANS}.PERCENTAGE`)},
    {value: FieldType.STRING, label: t(`${COMMON_FIELD_TYPES_TRANS}.STRING`)},
    {value: FieldType.FLOAT, label: t(`${COMMON_FIELD_TYPES_TRANS}.FLOAT`)}
  ];

  const handleValueChange = (inputValue: string, index?: number): void => {
    if (!isNil(index)) {
      const updatedItemsCopy = [...updatedItems];
      updatedItemsCopy[index].value = inputValue;
      setUpdatedItems(updatedItemsCopy);
    } else {
      setValue(inputValue);
    }
  };

  const handleTypeChange = (type: string | null, index: number): void => {
    const updatedItemsCopy = [...updatedItems];
    updatedItemsCopy[index].fieldType = FieldType[type as keyof typeof FieldType];
    updatedItemsCopy[index].value = undefined;
    setUpdatedItems(updatedItemsCopy);
  };

  const handleNameChange = (name: string | null, index: number): void => {
    const updatedItemsCopy = [...updatedItems];
    updatedItemsCopy[index].name = name || undefined;
    setUpdatedItems(updatedItemsCopy);
  };

  const renderField = (item: TableCheckItem, setValue: (value: string) => void): ReactElement => {
    const numberValue = NumberHelper.toNumberOrUndefined(item.value);
    const setNumberValue = (value: number | null): void => setValue(value ? String(value) : '');

    switch (item.fieldType) {
      case FieldType.CASH:
        return <NxCashInput className={styles.fieldInput}
                            label={t(`${COMMON_FIELD_TYPES_TRANS}.CASH`)}
                            value={numberValue}
                            onChange={setNumberValue} />;
      case FieldType.INTEGER:
        return <NxNumberInput className={styles.fieldInput}
                              label={t(`${COMMON_FIELD_TYPES_TRANS}.INTEGER`)}
                              value={numberValue}
                              onChange={setNumberValue} />;
      case FieldType.FLOAT:
        return <NxNumberInput className={styles.fieldInput}
                              label={t(`${COMMON_FIELD_TYPES_TRANS}.FLOAT`)}
                              value={numberValue}
                              step={0.01}
                              onChange={setNumberValue} />;
      case FieldType.PERCENTAGE:
        return <NxPercentageInput className={styles.fieldInput}
                                  label={t(`${COMMON_FIELD_TYPES_TRANS}.PERCENTAGE`)}
                                  value={numberValue}
                                  onChange={setNumberValue} />;
      case FieldType.STRING:
        return <NxInput className={styles.fieldInput}
                        label={t(`${COMMON_FIELD_TYPES_TRANS}.STRING`)}
                        value={item.value}
                        onChange={(value): void => setValue(value ? getInputValue(value) : '')} />;
      default:
        return <></>;
    }
  };

  const handleSave = (): void => {
    onSave(updatedItems);
  };

  const handleAddItem = (): void => {
    const item = {name: name, fieldType: fieldType, value: value};
    setUpdatedItems([...updatedItems, item]);
    setName(undefined);
    setFieldType(FieldType.STRING);
    setValue(undefined);
  };

  const handleRemoveItem = (index: number): void => {
    const updatedItemsCopy = [...updatedItems];
    updatedItemsCopy.splice(index, 1);
    setUpdatedItems(updatedItemsCopy);
  };

  return (
    <>
      <div className={styles.fields}>
        {updatedItems.map((item, index) => <>
          <div className={styles.fieldRow} key={index}>
            <NxInput className={styles.input}
                     label={t(`${COMMON_FIELDS_TRANS}.NAME`)}
                     onChange={(value): void => handleNameChange(value ? getInputValue(value) : '', index)}
                     value={item.name} />
            <NxSelect className={styles.type}
                      value={item.fieldType}
                      options={fieldTypeOptions}
                      label={t(`${COMMON_FIELDS_TRANS}.TYPE`)}
                      onChange={(value): void => handleTypeChange(value, index)}
            />
            {renderField(item, (value) => handleValueChange(value, index))}
            <BinButton onClick={(): void => handleRemoveItem(index)} />
          </div>
        </>)}
      </div>
      <div className={styles.fieldRow}>
        <NxInput label={t(`${COMMON_FIELDS_TRANS}.NAME`)}
                 onChange={(event): void => setName(getInputValue(event))}
                 value={name}
        />
        <NxSelect className={styles.type}
                  value={fieldType}
                  options={fieldTypeOptions}
                  label={t(`${COMMON_FIELDS_TRANS}.TYPE`)}
                  onChange={(value): void => setFieldType(value || undefined)}
        />
        {renderField({name: name || undefined, fieldType: fieldType || undefined, value: value || undefined},
          (value) => handleValueChange(value))}
        <NxButton className={styles.add} onClick={handleAddItem}>
          {t('COMMON.ADD')}
        </NxButton>
      </div>
      <div className={clsx(styles.row, styles.row_endAligned)}>
        <NxButton onClick={handleSave}>
          {t('COMMON.SAVE')}
        </NxButton>
      </div>
    </>
  );
};

export default TableCheckList;
