import React, {ReactElement} from 'react';
import FormColumn from '../../shared/form-column/FormColumn';
import FormGroup from '../../shared/form-column/FormGroup';
import ReadOnlyInput from './ReadOnlyInput';
import {IndividualDataTO} from '../loan-application/steps/customer-data/customer-data-form.model';
import ReadOnlyPhoneInput from './ReadOnlyPhoneInput';
import style from './ConsolidateCustomerDataReview.module.scss';

interface Props {
  individualData?: IndividualDataTO;
  getDictionaryEntryName: (id?: number) => string;
}

const IndividualCustomerDataReview = ({individualData, getDictionaryEntryName}: Props): ReactElement => {

  const ReadOnlyInputWithStyle = ({label, value, isBoolean = false}: {
    label: string,
    value?: string,
    isBoolean?: boolean
  }): ReactElement => {
    return <ReadOnlyInput label={label} value={value} isBoolean={isBoolean} className={style.input} />;
  };

  const ReadOnlyPhoneInputWithStyle = ({label, value}: {
    label: string,
    value?: string,
  }): ReactElement => {
    return <ReadOnlyPhoneInput label={label} value={value} className={style.input} />;
  };

  return (
    <>
      <FormColumn>
        <FormGroup header='Personal data'>
          <ReadOnlyInputWithStyle label='First name' value={individualData?.firstName} />
          <ReadOnlyInputWithStyle label='Last name'
                                  value={individualData?.lastName} />
          <ReadOnlyInputWithStyle label='Middle name'
                                  value={individualData?.middleName} />
          <ReadOnlyInputWithStyle label='Title'
                                  value={getDictionaryEntryName(Number(individualData?.titleId))} />
          <ReadOnlyInputWithStyle label='Nickname'
                                  value={individualData?.nickname} />
          <ReadOnlyInputWithStyle label='Extension'
                                  value={individualData?.extension} />
        </FormGroup>
        <FormGroup header='Compliance'>
          <ReadOnlyInput label='VIP'
                         value={individualData?.vip}
                         isBoolean />
          <ReadOnlyInputWithStyle label='PEP'
                                  value={individualData?.pep}
                                  isBoolean />
          <ReadOnlyInputWithStyle label='Risk level'
                                  value={getDictionaryEntryName(Number(individualData?.riskLevelId))} />
          <ReadOnlyInputWithStyle label='DOSRI'
                                  value={individualData?.dosri}
                                  isBoolean />
          <ReadOnlyInputWithStyle label='Occupation'
                                  value={getDictionaryEntryName(Number(individualData?.occupationId))} />
          <ReadOnlyInputWithStyle label='Bank employee'
                                  value={individualData?.bankEmployee}
                                  isBoolean />
        </FormGroup>
        <FormGroup header='Relatives'>
          <ReadOnlyInputWithStyle label="Mother's first name"
                                  value={individualData?.mothersFirstName} />
          <ReadOnlyInputWithStyle label="Mother's first name"
                                  value={individualData?.mothersMaidenName} />
          <ReadOnlyInputWithStyle label="Mother's first name"
                                  value={individualData?.mothersMiddleName} />
        </FormGroup>
      </FormColumn>
      <FormColumn>
        <FormGroup header='Contact data'>
          <ReadOnlyInputWithStyle label='Email'
                                  value={individualData?.email} />
          <ReadOnlyPhoneInputWithStyle label='Mobile number' value={individualData?.mobileNumber} />
          <ReadOnlyPhoneInputWithStyle label='Work number' value={individualData?.workNumber} />
          <ReadOnlyPhoneInputWithStyle label='Landline number' value={individualData?.landlineNumber} />
        </FormGroup>
        <FormGroup header='Demographics'>
          <ReadOnlyInputWithStyle label='Gender'
                                  value={getDictionaryEntryName(Number(individualData?.genderId))} />
          <ReadOnlyInputWithStyle label='Civil status'
                                  value={getDictionaryEntryName(Number(individualData?.civilStatusId))} />

          <ReadOnlyInputWithStyle label='Birth date'
                                  value={individualData?.birthDate} />
          <ReadOnlyInputWithStyle label='Birth country'
                                  value={getDictionaryEntryName(Number(individualData?.birthCountryId))} />
          <ReadOnlyInputWithStyle label='Birth place'
                                  value={individualData?.birthPlace} />
          <ReadOnlyInputWithStyle label='Residence'
                                  value={getDictionaryEntryName(Number(individualData?.residenceId))} />
          <ReadOnlyInputWithStyle label='Citizenship'
                                  value={getDictionaryEntryName(Number(individualData?.citizenshipId))} />
        </FormGroup>
      </FormColumn>
    </>
  );
};

export default IndividualCustomerDataReview;
