import {Formik, FormikProps} from 'formik';
import React, {ReactElement, useContext, useEffect} from 'react';
import {array, object, string, number, boolean, mixed} from 'yup';
import {PhaseName} from '../../../../../constants/api-urls';
import usePost from '../../../../../shared/hooks/use-post.hook';
import usePut from '../../../../../shared/hooks/use-put.hook';
import {DictionaryEntry} from '../../../../../shared/model/dictionary.model';
import {PhaseValidationResult} from '../../../../../shared/model/phase-validation-result.model';
import {Phase} from '../../../../../shared/model/phase.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {AppSnackbarContext} from '../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import {DraggableCheckGroup, toCheckGroups} from '../../../shared/draggable-checks/draggable-checks.model';
import DraggableChecks from '../../../shared/draggable-checks/DraggableChecks';
import {
  KeyedApprovalRule,
  toApprovalRule
} from '../../loan-configuration-step/approval-configuration/approval-rule-items/keyed-approval-rules.model';
import LoanConfigurationStep from '../../loan-configuration-step/LoanConfigurationStep';
import {LoanConfigurationContext} from '../../LoanConfiguration';
import {getInitialApprovalRulesValues} from '../hooks/use-initial-approval-rules-values.hook';
import styles from './EligibilityCriteria.module.scss';
import { getCheckFieldValidationSchema } from '../../../../../utils/step-form-utils/validation-schema/check-field-validation-schema';

interface FormFields {
  approvalRules?: KeyedApprovalRule[];
  isApprovalRequired?: boolean;
}

interface Props {
  config: Phase;
  provinceEntries: DictionaryEntry[];
  residenceEntries: DictionaryEntry[];
  occupationEntries: DictionaryEntry[];
  ageCheckTypeEntries: DictionaryEntry[];
  nationalityEntries: DictionaryEntry[];
  checkGroups: DraggableCheckGroup[];
  updateCheckGroups: (checkGroups: DraggableCheckGroup[]) => void;
}

const EligibilityCriteriaForm = ({
                                   config,
                                   provinceEntries,
                                   residenceEntries,
                                   ageCheckTypeEntries,
                                   nationalityEntries,
                                   occupationEntries,
                                   checkGroups,
                                   updateCheckGroups
                                 }: Props): ReactElement => {

  const {showErrorMessage} = useContext(AppSnackbarContext);
  const {process, setIsSaveDisabled} = useContext(LoanConfigurationContext);
  const phaseUrl = ApiHelper.getPhaseConfigurationUrl(process.id, PhaseName.ELIGIBILITY);
  const updatePhase = usePut<void, Phase>(phaseUrl);
  const validatePhase = usePost<PhaseValidationResult, Phase>(`${phaseUrl}/validate`);

  const buildPhaseConfig = (formFields: FormFields): Phase => ({
    ...config,
    approvalRules: formFields.isApprovalRequired ? formFields.approvalRules?.map(toApprovalRule) : [],
    checkGroups: toCheckGroups(checkGroups)
  });

  const handleSave = async (formFields: FormFields): Promise<void> =>
    await updatePhase(buildPhaseConfig(formFields)).catch(error => showErrorMessage(error.message));

  const getInitialValues = (config: Phase): FormFields => ({
    ...getInitialApprovalRulesValues(config)
  });

  const validateChecks = (checkGroups: DraggableCheckGroup[]) => {
    getCheckFieldValidationSchema().validate(checkGroups, {abortEarly: false})
      .then(() => {
        setIsSaveDisabled(false);
      })
      .catch((err) => {
        setIsSaveDisabled(true);
      });
  };

  const onSetGroups = async (checkGroups: DraggableCheckGroup[]): Promise<void> => {
    validateChecks(checkGroups);
    updateCheckGroups(checkGroups);
  };

  const entries = {
    province: provinceEntries,
    residence: residenceEntries,
    ageCheckTypes: ageCheckTypeEntries,
    nationality: nationalityEntries,
    occupations: occupationEntries
  };

  // Handles existing checks that have no action owners or name 
  useEffect(() => {
    validateChecks(checkGroups);
  }, []);

  return (
    <Formik<FormFields> initialValues={getInitialValues(config)} onSubmit={handleSave}>
      {
        ({values, submitForm}: FormikProps<FormFields>): React.ReactElement => {
          const validate = (): Promise<PhaseValidationResult> => validatePhase(buildPhaseConfig(values));

          return <LoanConfigurationStep handleSave={submitForm} validationFunction={validate}>
            <div className={styles.criteria}>
              <DraggableChecks checkGroups={checkGroups}
                               setCheckGroups={onSetGroups}
                               phaseId={config.id}
                               entries={entries}
                               handleAutomaticCheck />
            </div>
          </LoanConfigurationStep>;
        }
      }
    </Formik>
  );
};

export default EligibilityCriteriaForm;
