import {NxButton} from '@nextbank/ui-components';
import clsx from 'clsx';
import isNil from 'lodash/isNil';
import React, {ReactElement, useContext, useState} from 'react';
import {ReactComponent as ExploreIcon} from '../../../../../../assets/images/icon-explore.svg';
import {INITIALIZE_APPLICATION_URL} from '../../../../../../constants/api-urls';
import {RoutePaths} from '../../../../../../routes/routes.paths';
import {CalculatorDictionaryEntries} from '../../../../../../shared/hooks/use-calculator-dictionary-entries.hook';
import usePost from '../../../../../../shared/hooks/use-post.hook';
import {buildApplicationStepPath} from '../../../../../../utils/loan-steps-utils';
import {getSimulationDataBasedOnFields} from '../../../../../../utils/step-form-utils/calculator-fields-utils';
import {useGuardedHistory} from '../../../../../router/GuardedHistory';
import {AppSnackbarContext} from '../../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import {Application} from '../../../loan-application.model';
import {LoanApplicationContext} from '../../../LoanApplication';
import {CalculatorTrans} from '../Calculator';
import {CalculatorFormFields, LoanApplicationInitializeParams} from '../calculator.model';
import styles from './StartApplicationButton.module.scss';
import {CustomerType} from '../../../../../../shared/model/customer-type.model';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';

const CONSOLIDATE_LOANS_ERRORS_PREFIX = 'LOAN_APPLICATIONS.CONSOLIDATED_LOANS.ERRORS';

interface Props {
  calculatorFormFields: CalculatorFormFields,
  phaseId: number,
  dictionaryEntries: CalculatorDictionaryEntries,
  applicantSourceId?: string;
  isLoanConsolidation?: boolean,
  customerType?: CustomerType
}

export default function StartApplicationButton(
  {calculatorFormFields, phaseId, dictionaryEntries, applicantSourceId, isLoanConsolidation, customerType}: Props
): ReactElement {

  const {t} = useTranslation();
  const history = useGuardedHistory();
  const {showErrorMessage} = useContext(AppSnackbarContext);
  const {processId, setApplication} = useContext(LoanApplicationContext);
  const [isApplicationStarting, setIsApplicationStarting] = useState(false);
  const initializeApplication = usePost<Application, LoanApplicationInitializeParams>(INITIALIZE_APPLICATION_URL);

  const startApplication = async (): Promise<void> => {
    const fields = calculatorFormFields;
    if (!fields.simulation) {
      return;
    }

    const consolidatedLoansChanged = !isEqual(calculatorFormFields.simulation?.input.remadeFromLoanIds, calculatorFormFields.remadeFromLoanIds);
 
    if(consolidatedLoansChanged) {
      showErrorMessage(t(`${CONSOLIDATE_LOANS_ERRORS_PREFIX}.SIMULATE_REQUIRED_LOANS_CHANGED`));
      return;
    }

    const remainingBalance = calculatorFormFields.simulation?.result?.remadeLoanBalance ?? 0;
    const releaseAmount = calculatorFormFields.simulation?.result?.releaseAmount ?? 0
    const sufficientBalRenewalPrincipal = releaseAmount >= remainingBalance;
    if (!sufficientBalRenewalPrincipal) {
      showErrorMessage(t(`${CONSOLIDATE_LOANS_ERRORS_PREFIX}.INSUFFICIENT_RELEASE_AMOUNT`, {releaseAmount, remainingBalance}));
      return;
    }

    setIsApplicationStarting(true);

    const data = {
      processId,
      principalAmount: fields.simulation.input.principalAmount,
      simulation: {
        phaseId,
        input: getSimulationDataBasedOnFields(fields, dictionaryEntries, processId)
      }
    };

    return initializeApplication(data)
      .then(application => {

        if (!application) {
          return;
        }

        setIsApplicationStarting(false);
        setApplication(application);
        if (isLoanConsolidation && customerType) {
          const consolidatePath = buildApplicationStepPath(RoutePaths.CUSTOMER_DATA,
            processId,
            `${application.id}/${applicantSourceId}/${customerType.toLocaleLowerCase()}`);
          history.push(consolidatePath);
        } else {
          history.push(buildApplicationStepPath(RoutePaths.CUSTOMER_DATA, processId, application.id));
        }
      })
      .catch(error => {
        setIsApplicationStarting(false);
        showErrorMessage(error.message);
      });
  };

  const disabled = isNil(calculatorFormFields.simulation?.result) || isApplicationStarting;

  return (
    <NxButton className={clsx(styles.startButton, {[styles.startButton_disabled]: disabled})}
              onClick={startApplication}
              loaded={!isApplicationStarting}
              startIcon={<ExploreIcon />}
              disabled={disabled}>
      <CalculatorTrans>START_APPLICATION</CalculatorTrans>
    </NxButton>
  );
}
