import {CheckGroup, CheckGroupTO, fromCheckGroupTO} from './check-group.model';
import {Check, CheckTO, fromCheckTO} from './check.model';
import { LoanCreationType } from './creation-type.model';
import {Field} from './field.model';

export const APPROVAL_RULES = 'approvalRules';

export interface PhaseTO<T_CHECK_GROUP = CheckGroupTO, T_CHECK = CheckTO> {
  [APPROVAL_RULES]?: ApprovalRule[];
  id: number;
  processId: number;
  name: string;
  orderNo: number;
  // LOS-372 TODO to be removed when loan application updated
  checks: T_CHECK[];
  checkGroups: T_CHECK_GROUP[];
  type: PhaseType;
  creationTypes?: LoanCreationType[];
}

export interface ApprovalRule<APPROVERS = Approver[]> {
  id?: number;
  phaseId?: number;
  minAmount?: number;
  maxAmount?: number;
  approvers: APPROVERS;
}

export interface Approver {
  id?: number;
  approvalRuleId?: number;
  roleIds: number[];
  branchIds: number[];
}

export interface NumberedApprover extends Approver {
  ordinalNumber: number;
}

export type Phase = PhaseTO<CheckGroup, Check>;

export interface PhaseWithStaticFields extends Phase {
  staticFields: Field[];
}

export const fromPhaseTO = (phaseTO: PhaseTO): Phase => ({
  ...phaseTO,
  checkGroups: phaseTO.checkGroups ? phaseTO.checkGroups.map(checkGroup => fromCheckGroupTO(checkGroup)) : [],
  // LOS-372 TODO to be removed when loan application updated
  checks: phaseTO.checks ? phaseTO.checks.map(check => fromCheckTO(check)) : []
});

export enum PhaseType {
  AGREEMENT = 'AgreementPhaseView',
  CALCULATOR = 'CalculatorPhaseView',
  CUSTOM_PHASE = 'CustomPhaseView',
  CUSTOMER_DATA = 'IndividualCustomerProfilingPhaseView',
  ELIGIBILITY = 'EligibilityPhaseView',
  GENERAL = 'GeneralPhaseView',
  LOAN_APPLICATION_DATA = 'LoanApplicationDataPhaseView',
  PRE_RELEASE = 'PreReleasePhaseView',
  SUMMARY = 'SummaryPhaseView'
}
