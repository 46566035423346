import {NxButton} from '@nextbank/ui-components';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, {useContext, useEffect, useState} from 'react';
import {DroppableProvided} from 'react-beautiful-dnd';
import {Trans} from 'react-i18next';
import {CheckType} from '../../../../../shared/model/check.model';
import {isAnyFilled} from '../../../../../utils/empty-checks-utils';
import {generateKey} from '../../../../../utils/generate-key';
import BinButton from '../../../../shared/bin-button/BinButton';
import DragDropItem from '../../../../shared/drag-drop/DragDropItem';
import DragDropItems from '../../../../shared/drag-drop/DragDropItems';
import Check from '../../check/Check';
import {DraggableCheck, DraggableCheckGroup} from '../draggable-checks.model';
import {DraggableChecksContext} from '../DraggableChecks';
import DraggableCheckForm from './DraggableCheck';
import styles from './DraggableCheckGroupForm.module.scss';
import DraggableCheckGroupHeader from './DraggableCheckGroupHeader';

interface Props {
  checkGroup: DraggableCheckGroup;
  provided: DroppableProvided;
  isDragging: boolean;
}

const newCheckInitValue: DraggableCheck = {
  name: '',
  automatic: undefined,
  type: CheckType.MANUAL,
  proofType: undefined,
  required: false,
  selfCare: false,
  key: '',
  maxAge: undefined,
  minAge: undefined,
  ageCheckTypeId: undefined,
  maxIncome: undefined,
  minIncome: undefined,
  integerValue: undefined,
  roleIds: [],
  orderNo: 0,
  enabled: true
};

const DraggableCheckGroupForm = ({checkGroup, provided, isDragging}: Props): React.ReactElement => {

  const {
    phaseId,
    provinceOptions,
    residenceOptions,
    nationalityOptions,
    occupationOptions,
    ageCheckTypeOptions
  } = useContext(DraggableChecksContext);
  const {checkGroups, setCheckGroups, handleAutomaticCheck} = useContext(DraggableChecksContext);
  const [newCheck, setNewCheck] = useState<DraggableCheck>(newCheckInitValue);

  useEffect((): void => {
    if (getGroupIndex() <= -1) {
      throw new Error('Custom group index out of range.');
    }
  }, [checkGroups]);

  const getGroupIndex = (): number => checkGroups.findIndex(group => group.key === checkGroup.key);

  const setChecks = (checks: DraggableCheck[]): void => {
    const updatedGroups = cloneDeep(checkGroups);
    updatedGroups[getGroupIndex()].checks = checks;
    setCheckGroups(updatedGroups);
  };

  const addCheck = (): void => {
    const updatedChecks = cloneDeep(checkGroups[getGroupIndex()].checks);
    updatedChecks.push(
      {
        ...newCheck,
        orderNo: updatedChecks.length,
        ...generateKey()
      }
    );

    setChecks(updatedChecks);
    setNewCheck(newCheckInitValue);
  };

  const removeCheck = (key: string): () => void => (): void => {
    const updatedCheckGroups = cloneDeep(checkGroups);
    const updatedCheckIndex = updatedCheckGroups[getGroupIndex()].checks.findIndex(check => check.key === key);
    updatedCheckGroups[getGroupIndex()].checks.splice(updatedCheckIndex, 1);
    setCheckGroups(updatedCheckGroups);
  };

  const updateCheck = (key: string): ((check: DraggableCheck) => void) => {
    const updatedCheckGroups = cloneDeep(checkGroups);
    const updatedCheckIndex = updatedCheckGroups[getGroupIndex()].checks.findIndex(check => check.key === key);

    return (updatedCheck: DraggableCheck): void => {
      updatedCheckGroups[getGroupIndex()].checks[updatedCheckIndex] = updatedCheck;
      setCheckGroups(updatedCheckGroups);
    };
  };

  const GroupHeader = <DraggableCheckGroupHeader groupIndex={getGroupIndex()} groupName={checkGroup.name} />;

  const checkItemFunction = (provided, isDragging, item): React.ReactElement =>
    <DraggableCheckForm check={item}
                        setCheck={updateCheck(item.key)}
                        provided={provided}
                        isDragging={isDragging}
                        groupIndex={getGroupIndex()}
                        endButton={<BinButton onClick={removeCheck(item.key)} />}
    />;

  const isActionOwnerSelected = newCheck.automatic || !isEmpty(newCheck.roleIds);
  const ageCheck = newCheck.type === CheckType.AGE && isAnyFilled([newCheck.minAge,
    newCheck.maxAge]) && !isNil(newCheck.ageCheckTypeId);
  const incomeCheck = newCheck.type === CheckType.INCOME && isAnyFilled([newCheck.minIncome, newCheck.maxIncome]);
  const manualCheck = newCheck.type === CheckType.MANUAL && isAnyFilled([newCheck.proofType]);
  const provinceCheck = newCheck.type === CheckType.PROVINCE && !isEmpty(newCheck.provinceIds);
  const residenceCheck = newCheck.type === CheckType.RESIDENCY && !isEmpty(newCheck.residenceIds);
  const nationalityCheck = newCheck.type === CheckType.NATIONALITY && !isEmpty(newCheck.nationalityIds);
  const occupationCheck = newCheck.type === CheckType.OCCUPATION && !isEmpty(newCheck.occupationIds);
  const occupationAndIncomeCheck = newCheck.type === CheckType.OCCUPATION_AND_INCOME && isAnyFilled([newCheck.minIncome,
    newCheck.maxIncome]) && !isEmpty(newCheck.occupationIds);
  const integerCheck = newCheck.type === CheckType.INTEGER && isAnyFilled([newCheck.integerValue]);
  const booleanValue = newCheck.type === CheckType.BOOLEAN && isAnyFilled([newCheck.booleanValue]);
  const cashValue = newCheck.type === CheckType.CASH && isAnyFilled([newCheck.cashValue]);
  const percentageValue = newCheck.type === CheckType.PERCENTAGE && isAnyFilled([newCheck.percentageValue]);
  const tableValue = newCheck.type === CheckType.TABLE && isAnyFilled([newCheck.tableItems]);

  const AddCheckButton = (): React.ReactElement =>
    <NxButton onClick={addCheck}
              disabled={!newCheck.name || !isActionOwnerSelected || !(incomeCheck || ageCheck || manualCheck
                || provinceCheck || residenceCheck || nationalityCheck || occupationCheck || occupationAndIncomeCheck
                || integerCheck || booleanValue || cashValue || percentageValue || tableValue)}>
      <Trans>COMMON.ADD</Trans>
    </NxButton>;

  return (
    <DragDropItem className={styles.container} provided={provided} isDragging={isDragging} item={GroupHeader}>
      <div className={styles.groupChecks}>
        {checkGroup.checks.length > 0 &&
          <DragDropItems<DraggableCheck>
            droppableId={`droppableCheckGroups-${checkGroup.key}`}
            items={checkGroup.checks}
            setItems={setChecks}
            itemComponentFunction={checkItemFunction} />
        }
        <div className={styles.addRow}>
          <Check check={newCheck}
                 setCheck={setNewCheck}
                 phaseId={phaseId}
                 dictionaryId={newCheck.dictionaryId}
                 endButton={<AddCheckButton />}
                 provinceOptions={provinceOptions}
                 residenceOptions={residenceOptions}
                 nationalityOptions={nationalityOptions}
                 ageCheckTypeOptions={ageCheckTypeOptions}
                 occupationOptions={occupationOptions}
                 handleAutomaticCheck={handleAutomaticCheck} />
        </div>
      </div>
    </DragDropItem>
  );
};

export default DraggableCheckGroupForm;
