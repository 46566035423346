import React, {ReactElement, useRef} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {CloseSidePanel} from '../../../../../shared/side-panel/SidePanel';
import SidePanelWithIconButton from '../../../../../shared/side-panel/SidePanelWithIconButton';
import {ReactComponent as DictionaryIcon} from '../../../../../../assets/images/icon-dictionary.svg';
import TableCheckList from './TableCheckList';
import {TableCheckItem} from '../../../../../../shared/model/check.model';

interface Props {
  tableItems?: TableCheckItem[];
  onSaveItems: (items: TableCheckItem[]) => void;
}

const TableCheckSidePanel = ({tableItems, onSaveItems}: Props): ReactElement => {
  const {t} = useTranslation();
  const tooltip = t('COMMON.TOOLTIPS.TOGGLE_TABLE_CONFIG');
  const header = <Trans>COMMON.TABLE_CONFIGURATION</Trans>;
  const panelRef = useRef<CloseSidePanel>(null);

  const handleSave = (items: TableCheckItem[]): void => {
    onSaveItems(items);
    panelRef.current && panelRef.current.close();
  };

  return (
    <SidePanelWithIconButton ref={panelRef} header={header} icon={<DictionaryIcon />} tooltip={tooltip}>
      <TableCheckList items={tableItems} onSave={handleSave} />
    </SidePanelWithIconButton>
  );
};

export default TableCheckSidePanel;
