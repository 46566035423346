import React, {ReactElement, useContext, useEffect} from 'react';
import {NxTabs} from '@nextbank/ui-components';
import {Form, FormikProps} from 'formik';
import {isEqual, union} from 'lodash';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import {useTranslation} from 'react-i18next';
import {
  AccountInformationOptions,
  LoanParametersOptions
} from '../../../../../shared/hooks/use-loan-application-data-options.hook';
import {PostRequest} from '../../../../../shared/hooks/use-post.hook';
import {LoanSimulation, LoanSimulationParams} from '../../../../../shared/model/loan-simulation.model';
import {PrintType} from '../../../../../shared/model/print.model';
import {
  validateAndSubmitForm,
  VALIDATION_INDICATOR_FIELD
} from '../../../../../utils/step-form-utils/validation-schema/validation-schema-utils';
import {
  LoanApplicationDataPhase
} from '../../../../loan-configurations/loan-configuration/steps/loan-application-data/loan-application-data-phase.model';
import {LoanApplicationContext} from '../../LoanApplication';
import LoanApplicationStep from '../shared/loan-application-step/LoanApplicationStep';
import AccountInformation from '../shared/simulation/account-information/AccountInformation';
import {FeesOverride} from '../shared/simulation/fees-override/FeesOverride';
import LoanParameters from '../shared/simulation/loan-parameters/LoanParameters';
import {checkAccountInformationError, checkLoanParametersError} from './loan-application-data-form-utils';
import {
  LoanApplicationDataFormFields,
  SIMULATION_PARAMS_CHANGED_INDICATOR_FIELD,
  SIMULATION_REQUIRED_INDICATOR_FIELD
} from './loan-application-data-form.model';
import {PrefixTrans} from './LoanApplicationData';
import {sortSimulatedFees} from '../../../../../utils/fees-override-helper';
import ConsolidatedLoans from '../shared/consolidated-loans/ConsolidatedLoans';

const CONSOLIDATE_LOANS_ERRORS_PREFIX = 'LOAN_APPLICATIONS.CONSOLIDATED_LOANS.ERRORS';

interface Props {
  config: LoanApplicationDataPhase;
  formikProps: FormikProps<LoanApplicationDataFormFields>;
  runSimulation: PostRequest<LoanSimulation[], LoanSimulationParams>;
  suggestPrincipal: PostRequest<LoanSimulation[], LoanSimulationParams>;
  getSimulationDataValues: (fields: LoanApplicationDataFormFields) => LoanSimulationParams;
  showErrorMessage: (message: string) => void;
  options: {
    accountInformationOptions: AccountInformationOptions;
    loanParametersOptions: LoanParametersOptions;
  };
  setMinFirstPaymentDate: (string) => void;
  isConsolidateLoan: boolean;
}

export const LoanApplicationDataForm = (
  {
    formikProps,
    config,
    runSimulation,
    suggestPrincipal,
    getSimulationDataValues,
    options,
    showErrorMessage,
    setMinFirstPaymentDate,
    isConsolidateLoan
  }: Props
): ReactElement => {

  const {t} = useTranslation();
  const {process, application} = useContext(LoanApplicationContext);
  const loanConfig = process.loanProduct;
  const {values, dirty, errors, setFieldValue, submitForm, validateForm, isSubmitting, setSubmitting} = formikProps;
  const simulatedFees = values.simulation?.input.simulatedFees;

  const hasBorrowerSourceId = !!application?.borrower?.sourceId;
  const hasBorrowerBirthDate = !!application?.borrower?.individualData?.birthDate;

  const {
    loanClassId, loanPurposeId, loanSecurityId, loanEconomicActivityId, microfinanceClassificationId, misGroupId,
    borrowerTypeId, transactionTypeId, cicContractTypeId, loanPurposeToIndustryId
  } = mapValues(values, 'value');

  const simulationInput = {
    ...getSimulationDataValues(values),
    simulatedFees,
    accountInformation: {
      loanClassId, loanPurposeId, loanSecurityId, loanEconomicActivityId, microfinanceClassificationId, misGroupId,
      borrowerTypeId, transactionTypeId, cicContractTypeId, loanPurposeToIndustryId
    },
    ...(hasBorrowerSourceId && {customerId: application?.borrower?.sourceId}),
    ...(!hasBorrowerSourceId && hasBorrowerBirthDate && {birthDate: application?.borrower?.individualData?.birthDate}),
  };

  const submitSuggestPrincipal = async (): Promise<void> => {
    validate();
    setSubmitting(true);
    suggestPrincipal(simulationInput)
      .then(simulations => {
        if (!isEmpty(simulations) && !isNil(simulations)) {
          setFieldValue('principal.value', simulations[0].principalAmount, true);
        }
      })
      .catch(error => {
        setSimulation(undefined);
        showErrorMessage(error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const submitSimulation = async (): Promise<void> => {
    validate();

    setSubmitting(true);
    runSimulation(simulationInput)
      .then(simulations => {
        if (!isEmpty(simulations) && !isNil(simulations)) {
          setSimulation(simulations[0], simulationInput);
        }
      })
      .catch(error => {
        setSimulation(undefined);
        showErrorMessage(error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const validate = async (): Promise<void> => {
    const validate = true;
    const simulationParamsChanged = false;
    setFieldValue(VALIDATION_INDICATOR_FIELD, validate);
    setFieldValue(SIMULATION_REQUIRED_INDICATOR_FIELD, simulationParamsChanged);

    const errors = await validateForm({...values, validate, simulationParamsChanged});

    if (!isEmpty(errors)) {
      return;
    }
  };

  const setSimulation = (result?: LoanSimulation, input?: LoanSimulationParams): void =>
    setFieldValue('simulation', result && input ? getUpdatedSimulationFromData(result, input) : undefined);

  const getUpdatedSimulationFromData = (result: LoanSimulation,
                                        input: LoanSimulationParams): {
    result: LoanSimulation,
    input: LoanSimulationParams
  } => {
    return {
      result: result,
      input: {
        ...input,
        simulatedFees: result.simulatedFees
      }
    };
  };

  const resetSimulationParameters = (): void => {
    setFieldValue('simulation', undefined);
  };

  const simulationParamsChanged = values.simulation?.result && !isEqual(values.simulation.input, simulationInput);
  const consolidatedLoansChanged = !isEqual(values.simulation?.input.remadeFromLoanIds, values.remadeFromLoanIds);

  const loanParametersTab = {
    key: 'LOAN_PARAMETERS',
    error: (dirty && simulationParamsChanged) || checkLoanParametersError(errors),
    label: <PrefixTrans>LOAN_PARAMETERS</PrefixTrans>,
    tabPanel: <LoanParameters fixedPaymentOptions={config?.fixedPaymentOptions}
                              loanParametersOptions={options.loanParametersOptions}
                              simulationParamsChanged={dirty && simulationParamsChanged}
                              submitSimulation={submitSimulation}
                              resetSimulationParameters={resetSimulationParameters}
                              setFieldValue={setFieldValue}
                              isSubmitting={isSubmitting}
                              values={values}
                              loanConfig={loanConfig}
                              setMinFirstPaymentDate={setMinFirstPaymentDate}
                              isConsolidateLoan={isConsolidateLoan}
                              submitSuggestPrincipal={submitSuggestPrincipal} />
  };

  const accountInformationTab = {
    key: 'ACCOUNT_INFORMATION',
    error: checkAccountInformationError(errors),
    label: <PrefixTrans>ACCOUNT_INFORMATION.LABEL</PrefixTrans>,
    tabPanel: <AccountInformation values={values} options={options.accountInformationOptions} />
  };

  const feesOverrideTab = {
    key: 'FEES_OVERRIDE',
    label: <PrefixTrans>FEES_OVERRIDE.LABEL</PrefixTrans>,
    tabPanel: <FeesOverride fees={simulatedFees} />
  };

  const consolidatedLoansTab = {
    key: 'CONSOLIDATED_LOANS',
    label: <PrefixTrans>CONSOLIDATED_LOANS.LABEL</PrefixTrans>,
    tabPanel: <ConsolidatedLoans remadeFromLoanIds={union(values.simulation?.input.remadeFromLoanIds,
      values.remadeFromLoanIds)}
                                 setFieldValue={setFieldValue} />
  };

  const handleSave = async (validate: boolean): Promise<void> => {
    if(consolidatedLoansChanged) {
      return Promise.reject(t(`${CONSOLIDATE_LOANS_ERRORS_PREFIX}.SIMULATE_REQUIRED_LOANS_CHANGED`));
    }

    const remainingBalance = values.simulation?.result?.remadeLoanBalance ?? 0;
    const releaseAmount = values.simulation?.result?.releaseAmount ?? 0
    const sufficientBalRenewalPrincipal = releaseAmount >= remainingBalance;
    if (!sufficientBalRenewalPrincipal) {
      return Promise.reject(t(`${CONSOLIDATE_LOANS_ERRORS_PREFIX}.INSUFFICIENT_RELEASE_AMOUNT`, {releaseAmount, remainingBalance}));
    }

    return validateAndSubmitForm<LoanApplicationDataFormFields>(
      {
        ...values,
        [SIMULATION_REQUIRED_INDICATOR_FIELD]: true,
        [SIMULATION_PARAMS_CHANGED_INDICATOR_FIELD]: simulationParamsChanged
      }, validate, submitForm, validateForm, setFieldValue, t);
  }

  useEffect(() => {
    if (simulatedFees !== undefined) {
      sortSimulatedFees(simulatedFees);
    }
  }, [simulatedFees]);

  return (
    <LoanApplicationStep handleSave={handleSave}
                         phaseId={config?.id}
                         dataChanged={dirty}
                         approvalRules={config?.approvalRules}
                         printType={PrintType.LOAN_APPLICATION_DATA}>
      <Form>
        <NxTabs tabs={[loanParametersTab,
          accountInformationTab,
          feesOverrideTab,
          ...(isConsolidateLoan ? [consolidatedLoansTab] : [])]} />
      </Form>
    </LoanApplicationStep>
  );
};

