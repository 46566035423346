import React from 'react';
import {LoanSimulation} from '../../../../../../../shared/model/loan-simulation.model';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import ReadonlyInput from './readonly-input/ReadonlyInput';
import styles from './SimulationParameters.module.scss';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.SIMULATED_LOAN_PARAMETERS');

interface Props {
  simulation?: LoanSimulation;
  isConsolidateLoan?: boolean;
}

interface SimulationField {
  label: string;
  value: keyof Omit<LoanSimulation, 'simulatedAmortizationSchedule' | 'paymentInterval' | 'simulatedFees' | 'remadeFromLoanIds'>;
  isPercentage?: boolean;
}

export default function SimulationParameters({simulation, isConsolidateLoan = false}: Props): React.ReactElement {

  const fields: SimulationField[] = [
    {value: 'principalAmount', label: 'PRINCIPAL'},
    {value: 'remadeLoanBalance', label: 'TOTAL_OUTSTANDING_BALANCE'},
    {value: 'totalReleaseFees', label: 'TOTAL_RELEASE_FEES'},
    {value: 'releaseAmount', label: 'RELEASE'},
    {value: 'totalCost', label: 'TOTAL_COST'},
    {value: 'totalRepayment', label: 'TOTAL_REPAYMENT'},
    {value: 'interestRate', label: 'INTEREST_RATE', isPercentage: true},
    {value: 'interestAmount', label: 'INTEREST_AMOUNT'},
    {value: 'advanceInterest', label: 'ADVANCE_INTEREST'},
    {value: 'interestBalance', label: 'AMORTIZATION_INTEREST'},
    {value: 'eirPeriodic', label: 'PERIODIC_EIR', isPercentage: true},
    {value: 'grossEirPeriodic', label: 'GROSS_PERIODIC_EIR', isPercentage: true},
    {value: 'eirAnnual', label: 'ANNUAL_EIR', isPercentage: true},
    {value: 'grossEirAnnual', label: 'GROSS_ANNUAL_EIR', isPercentage: true},
    {value: 'averageAmortizationAmount', label: 'AVERAGE_AMORTIZATION_AMOUNT'}
  ];

  const filteredFields = isConsolidateLoan
    ? fields
    : fields.filter(field => field.value !== 'remadeLoanBalance');

  const computedSimulation = isConsolidateLoan && simulation
  ? {
      ...simulation,
      releaseAmount: (simulation.releaseAmount ?? 0) - (simulation.remadeLoanBalance ?? 0),
    }
  : simulation;

  return (
    <div className={styles.column}>
      <div className={styles.simulation}>
        <div className={styles.header}>
          <PrefixTrans>HEADER</PrefixTrans>
        </div>
        {
          filteredFields.map((field, index) =>
            <ReadonlyInput value={computedSimulation ? computedSimulation[field.value] : 0}
                           label={<PrefixTrans>{field.label}</PrefixTrans>}
                           isPercentage={field.isPercentage}
                           key={index} />
          )
        }
      </div>
    </div>
  );
}

