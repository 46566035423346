import {NxInput, NxMultiSelect} from '@nextbank/ui-components';
import React, {ChangeEvent, useState} from 'react';
import {getInputValue} from '../../../../../../utils/input-utils';
import BinButton from '../../../../../shared/bin-button/BinButton';
import {Trans} from 'react-i18next';
import {LoanCreationType} from '../../../../../../shared/model/creation-type.model';
import styles from './CustomPhaseInput.module.scss';

const CREATION_TYPE_OPTIONS = [
  {label: 'New Loan', value: LoanCreationType.NEW_LOAN},
  {label: 'Reloan', value: LoanCreationType.RELOAN},
  {label: 'Additional loan', value: LoanCreationType.ADDITIONAL_LOAN},
  {label: 'Extension', value: LoanCreationType.EXTENSION},
  {label: 'Consolidation', value: LoanCreationType.CONSOLIDATION}
];

interface Props {
  className: string;
  label: React.ReactNode;
  onBlur: (event: ChangeEvent) => void;
  onRemove: () => void;
  onCreationTypeBlur: (creationTypes: string[]) => void;
  initValue: string;
  initCreationTypeValue?: string[];
}

/**
 * Wrapping of NxInput to prevent loosing field focus on value change. Caused by rerendering whole input, not value.
 * Component keeps value in local state and every change is propagated outsite when onBlur invoked.
 *
 * @param className
 * @param label
 * @param onBlur on blur method. The only way to get input value.
 * @param initValue
 * @param onRemove
 *
 */
export default function CustomPhaseInput({
                                           className,
                                           label,
                                           initValue,
                                           initCreationTypeValue,
                                           onBlur,
                                           onCreationTypeBlur,
                                           onRemove
                                         }: Props): React.ReactElement {
  const [value, setValue] = useState(initValue);
  const [creationTypeValue, setCreationTypeValue] = useState(initCreationTypeValue);

  const handleChange = (e: ChangeEvent): void => setValue(getInputValue(e));
  const handleCreationTypeChange = (values: string[]): void => setCreationTypeValue(values);

  return (
    <>
      <NxInput className={className} label={label} onChange={handleChange} onBlur={onBlur} value={value} />
      <BinButton onClick={onRemove} />
      <NxMultiSelect<string> className={styles.input}
                             label={<Trans>COMMON.FIELDS.CREATION_TYPE</Trans>}
                             onChange={handleCreationTypeChange}
                             options={CREATION_TYPE_OPTIONS}
                             value={creationTypeValue || []}
                             onBlur={onCreationTypeBlur} />
    </>
  );
}
