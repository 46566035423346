export interface CBSLoanDetails {
  loanId?: number;
  customerName?: string;
  productNumber?: string;
  productName?: string;
  principalAmount?: number;
  principalBalance?: number;
  status?: string;
  interestAmount?: number;
  interestBalance?: number;
  cbuAmount: number;
  pfAmount: number;
  tpAmount: number;
  pastDueInterestAmount: number;
  penaltiesAmount: number;
  customFeesAmount: number;
  isLocked?: boolean;
  daysLate?: number;
  paymentInterval?: string;
  maturityDate?: string;
  totalAmortizationNumber?: number;
  averageAmortizationAmount?: number;
}

export const VALID_STATUSES_FOR_CONSOLIDATION: string[] = ['ACTIVE',
  'PAST_DUE_PERFORMING',
  'PAST_DUE_NON_PERFORMING',
  'PAST_DUE_LITIGATION'];