import {TFunction} from 'i18next';
import {AnySchema, array, mixed, string, number} from 'yup';
import {FieldType} from '../../../shared/model/field.model';
import {getNxFileValidationSchema} from './nx-file-validation-schema';
import {
  getDefaultFieldLengthExceededError,
  getDefaultRequiredError,
  getSchemaBasedOnRequiredField,
  getTooBigError
} from './validation-schema-utils';
import {getDocumentFieldValidationSchema} from './document-validation-schema';

export const getCustomFieldValueSchema = (partialValidation = false, t: TFunction): AnySchema => {

  const requiredError = getDefaultRequiredError(t);
  const maxLengthSchema = string().max(512, getDefaultFieldLengthExceededError(t, 512));
  const maxIntegerSchema = number().max(2147483647, getTooBigError(t, 2147483647));

  const getFieldSchema = (required = false): AnySchema =>
    mixed().when('fieldType', fieldType => {
      switch (fieldType) {
        case FieldType.FILE:
          return getNxFileValidationSchema(required, requiredError);
        case FieldType.DOCUMENT:
          return getDocumentFieldValidationSchema(required, requiredError, t);
        case FieldType.DICTIONARY_MULTI:
          return required ? array().min(1, requiredError) : array();
        case FieldType.STRING:
          return required ? maxLengthSchema.required(requiredError) : maxLengthSchema;
          case FieldType.INTEGER:
            return required ? maxIntegerSchema.required(requiredError) : maxIntegerSchema;
        default:
          return required ? mixed().required(requiredError) : mixed();
      }
    });

  return getSchemaBasedOnRequiredField(partialValidation, getFieldSchema(), getFieldSchema(true));
};
