import {mixed, boolean, number, object, string, array} from 'yup';

export const getCheckFieldValidationSchema = () => {
    return array().of(
          object({
            checks: array().of(
              object({
                type: string().required(),
                automatic: boolean().default(false),
                roleIds: mixed().when('automatic', {
                  is: true,
                  then: array().of(number()), // No validation when automatic is true
                  otherwise: array().of(number().required()).min(1).required()
                }),
                name: string().trim().required()
              })
            ).required()
          })
        );
}