import {NxLoader} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import React, {useContext, useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import useGet from '../../../../../shared/hooks/use-get.hook';
import usePost from '../../../../../shared/hooks/use-post.hook';
import {PhaseTO} from '../../../../../shared/model/phase.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {generateKey} from '../../../../../utils/generate-key';
import {sortByOrderNo} from '../../../../../utils/sort-by-order';
import {TransHelper} from '../../../../../utils/trans-helper';
import {AppSnackbarContext} from '../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import LoanConfigurationStepWrapper from '../../loan-configuration-step/LoanConfigurationStepWrapper';
import {LoanConfigurationContext} from '../../LoanConfiguration';
import CustomPhasesConfig from './custom-phases-config/CustomPhasesConfig';
import {KeyedCustomPhase} from './custom-phases.model';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.EDIT_CUSTOM_PHASES');

export default function EditCustomPhases(): React.ReactElement {

  const [customPhases, setCustomPhases] = useState<KeyedCustomPhase[]>();
  const {showErrorMessage} = useContext(AppSnackbarContext);
  const {process, updateProcess} = useContext(LoanConfigurationContext);
  const {getCustomPhasesUrl, getCustomPhasesUpdateUrl} = ApiHelper;
  const fetchCustomPhases = useGet<PhaseTO[]>(getCustomPhasesUrl(process.id));
  const updateCustomPhases = usePost<void, KeyedCustomPhase[]>(getCustomPhasesUpdateUrl(process.id));

  useEffect(() => {
    fetchCustomPhases()
      .then(phases => {
        const keyedPhases =
          phases.map(({id, name, orderNo, creationTypes}) => ({id, name, orderNo, creationTypes, ...generateKey()}));

        setCustomPhases(sortByOrderNo(keyedPhases));
      })
      .catch(error => showErrorMessage(error.message));
  }, []);

  const handleSave = async (): Promise<void> => {
    if (isNil(customPhases)) {
      return;
    }

    const updatedPhases = customPhases.map((phase: KeyedCustomPhase, index: number) => (
        {
          ...phase,
          orderNo: index
        }
      ))

    await updateCustomPhases(updatedPhases)
      .then(() => updateProcess(true));
  };

  const stepLabel = <Trans>LOAN_STEPS_LABELS.EDIT_CUSTOM_PHASES</Trans>;
  const stepInstruction = <PrefixTrans>INSTRUCTION</PrefixTrans>;

  return isNil(customPhases) ? <NxLoader /> : (
    <LoanConfigurationStepWrapper stepInstruction={stepInstruction} label={stepLabel}>
      <CustomPhasesConfig phasesState={{customPhases: customPhases ?? [], setCustomPhases}}
                          inputLabel={<PrefixTrans>PHASE_NAME</PrefixTrans>}
                          handleSave={handleSave} />
    </LoanConfigurationStepWrapper>
  );
}
