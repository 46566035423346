import {NxTabs} from '@nextbank/ui-components';
import {Form, FormikProps} from 'formik';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import union from 'lodash/union';
import React, {ReactElement, ReactNode, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as WarningIcon} from '../../../../../assets/images/icon-status-warning.svg';
import {LOAN_SIMULATION_URL, SUGGEST_PRINCIPAL} from '../../../../../constants/api-urls';
import {LoanParametersOptions} from '../../../../../shared/hooks/use-loan-application-data-options.hook';
import usePost from '../../../../../shared/hooks/use-post.hook';
import {LoanConfiguration} from '../../../../../shared/model/loan-configuration.model';
import {LoanSimulation, LoanSimulationParams} from '../../../../../shared/model/loan-simulation.model';
import {PrintType} from '../../../../../shared/model/print.model';
import {
  validateAndSubmitForm,
  VALIDATION_INDICATOR_FIELD
} from '../../../../../utils/step-form-utils/validation-schema/validation-schema-utils';
import {TransHelper} from '../../../../../utils/trans-helper';
import {
  CalculatorPhase
} from '../../../../loan-configurations/loan-configuration/steps/calculator/calculator-phase.model';
import {AppSnackbarContext} from '../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import {Application} from '../../loan-application.model';
import {PrefixTrans} from '../loan-application-data/LoanApplicationData';
import LoanApplicationStep from '../shared/loan-application-step/LoanApplicationStep';
import {FeesOverride} from '../shared/simulation/fees-override/FeesOverride';
import LoanParameters from '../shared/simulation/loan-parameters/LoanParameters';
import {
  CalculatorFormFields,
  SIMULATION_PARAMS_CHANGED_INDICATOR_FIELD,
  SIMULATION_REQUIRED_INDICATOR_FIELD
} from './calculator.model';
import styles from './CalculatorForm.module.scss';
import {sortSimulatedFees} from '../../../../../utils/fees-override-helper';
import ConsolidatedLoans from '../shared/consolidated-loans/ConsolidatedLoans';

export const CalculatorTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.CALCULATOR');
export const FieldTrans = TransHelper.getPrefixedTrans('COMMON.FIELDS');

interface Props {
  config: CalculatorPhase;
  loanConfig: LoanConfiguration;
  formikProps: FormikProps<CalculatorFormFields>;
  getSimulationDataValues: (fields: CalculatorFormFields) => LoanSimulationParams;
  loanParametersOptions: LoanParametersOptions;
  stepNavigation?: ReactNode;
  application?: Application;
  setMinFirstPaymentDate: (string) => void;
  isConsolidateLoan?: boolean;
  applicantSourceId?: string;
}

export const CalculatorForm = (
  {
    config,
    loanConfig,
    formikProps,
    loanParametersOptions,
    getSimulationDataValues,
    stepNavigation,
    application,
    setMinFirstPaymentDate,
    isConsolidateLoan,
    applicantSourceId
  }: Props
): ReactElement => {

  const {t} = useTranslation();
  const runSimulation = usePost<LoanSimulation[], LoanSimulationParams>(LOAN_SIMULATION_URL);
  const suggestPrincipal = usePost<LoanSimulation[], LoanSimulationParams>(SUGGEST_PRINCIPAL);
  const {showErrorMessage} = useContext(AppSnackbarContext);

  const {values, submitForm, isSubmitting, setFieldValue, validateForm, setSubmitting} = formikProps;

  const hasBorrowerSourceId = !!application?.borrower?.sourceId || applicantSourceId;
  const hasBorrowerBirthDate = !!application?.borrower?.individualData?.birthDate;

  const simulatedFees = values.simulation?.input.simulatedFees;
  const simulationInput = {
    ...getSimulationDataValues(values),
    simulatedFees,
    ...(hasBorrowerSourceId ? {customerId: application?.borrower?.sourceId} : {customerId: applicantSourceId}),
    ...(!hasBorrowerSourceId && hasBorrowerBirthDate && {birthDate: application?.borrower?.individualData?.birthDate}),
  } as LoanSimulationParams;

  const submitSuggestPrincipal = async (): Promise<void> => {
    validate();

    setSubmitting(true);
    suggestPrincipal(simulationInput)
      .then(simulations => {
        if (!isEmpty(simulations) && !isNil(simulations)) {
          setFieldValue('principal.value', simulations[0].principalAmount, true);
        }
      })
      .catch(error => {
        setSimulation(undefined);
        showErrorMessage(error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  const submitSimulation = async (): Promise<void> => {

    validate();

    setSubmitting(true);
    runSimulation(simulationInput)
      .then(simulations => {
        if (!isEmpty(simulations) && !isNil(simulations)) {
          setSimulation(simulations[0], simulationInput);
        }
      })
      .catch(error => {
        setSimulation(undefined);
        showErrorMessage(error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const validate = async (): Promise<void> => {
    const validate = true;
    const simulationParamsChanged = false;
    setFieldValue(VALIDATION_INDICATOR_FIELD, validate);
    setFieldValue(SIMULATION_REQUIRED_INDICATOR_FIELD, simulationParamsChanged);

    const errors = await validateForm({...values, validate, simulationParamsChanged});

    if (!isEmpty(errors)) {
      return;
    }
  };

  const setSimulation = (result?: LoanSimulation, input?: LoanSimulationParams): void =>
    setFieldValue('simulation', result && input ? getUpdatedSimulationFromData(result, input) : undefined);

  const getUpdatedSimulationFromData = (result: LoanSimulation,
                                        input: LoanSimulationParams): {
    result: LoanSimulation,
    input: LoanSimulationParams
  } => {
    return {
      result: result,
      input: {
        ...input,
        simulatedFees: result.simulatedFees
      }
    };
  };


  const resetSimulationParameters = (): void => {
    setFieldValue('simulation', undefined);
  };

  const simulationParamsChanged = values.simulation?.result && !isEqual(values.simulation.input, simulationInput);

  const handleSave = async (validate: boolean): Promise<void> =>
    validateAndSubmitForm<CalculatorFormFields>(
      {
        ...values,
        [SIMULATION_REQUIRED_INDICATOR_FIELD]: true,
        [SIMULATION_PARAMS_CHANGED_INDICATOR_FIELD]: simulationParamsChanged
      }, validate, submitForm, validateForm, setFieldValue, t);

  const loanParametersTab = {
    key: 'LOAN_PARAMETERS',
    error: simulationParamsChanged,
    label: <PrefixTrans>LOAN_PARAMETERS</PrefixTrans>,
    tabPanel: <LoanParameters fixedPaymentOptions={config?.fixedPaymentOptions}
                              loanParametersOptions={loanParametersOptions}
                              submitSimulation={submitSimulation}
                              simulationParamsChanged={simulationParamsChanged}
                              setFieldValue={setFieldValue}
                              isSubmitting={isSubmitting}
                              values={values}
                              loanConfig={loanConfig}
                              resetSimulationParameters={resetSimulationParameters}
                              setMinFirstPaymentDate={setMinFirstPaymentDate}
                              isConsolidateLoan={isConsolidateLoan}
                              submitSuggestPrincipal={submitSuggestPrincipal} />
  };

  const feesOverrideTab = {
    key: 'FEES_OVERRIDE',
    label: <PrefixTrans>FEES_OVERRIDE.LABEL</PrefixTrans>,
    tabPanel: <FeesOverride fees={values.simulation?.input.simulatedFees} />
  };

  const consolidatedLoansTab = {
    key: 'CONSOLIDATED_LOANS',
    label: <PrefixTrans>CONSOLIDATED_LOANS.LABEL</PrefixTrans>,
    tabPanel: <ConsolidatedLoans remadeFromLoanIds={union(values.simulation?.input.remadeFromLoanIds,
      values.remadeFromLoanIds)}
                                 setFieldValue={setFieldValue} />
  };

  useEffect(() => {
    if (values.simulation?.input.simulatedFees !== undefined) {
      sortSimulatedFees(values.simulation?.input.simulatedFees);
    }
  }, [values]);

  return (
    <LoanApplicationStep stepInstruction={<CalculatorTrans>FILL_IN</CalculatorTrans>}
                         stepNavigation={stepNavigation}
                         handleSave={handleSave}
                         phaseId={config?.id}
                         printType={PrintType.SIMULATION}>
      {
        application?.batchId && <div className={styles.preApprovedLoanInfo}>
          <WarningIcon />
          <CalculatorTrans>PRE_APPROVED_LOAN_INFO</CalculatorTrans>
        </div>
      }
      <Form>
        <NxTabs tabs={[loanParametersTab, feesOverrideTab, ...(isConsolidateLoan ? [consolidatedLoansTab] : [])]} />
      </Form>
    </LoanApplicationStep>
  );
};
