import isNil from 'lodash/isNil';
import some from 'lodash/some';
import {UserData} from '../../../../../../security-context.model';
import {isSysAdminOrManager} from '../../../../../../utils/permissions-utils';
import {FieldDisablementReason} from '../configured-fields/field-disablement-reason.model';
import { Phase } from '../../../../../../shared/model/phase.model';
import { Application } from '../../../loan-application.model';


const hasFieldActionOwnerPermission = (permittedRolesIds?: number[], userData?: UserData, application?: Application) => {
  const userRoleIsAllowed = some(permittedRolesIds, role => some(userData?.roleIds, userRole => userRole === role));
  const userWithinLoanAppBranch = application?.branchId === userData?.branchId;

  return userRoleIsAllowed && userWithinLoanAppBranch;
}

const isUserHasPermission = (permittedRolesIds?: number[], userData?: UserData, application?: Application): boolean => {
  if (isNil(userData)) {
    return false;
  }

  if (isSysAdminOrManager(userData)) {
    return true;
  }

  return hasFieldActionOwnerPermission(permittedRolesIds, userData, application);
};

export const getDisablementReason =
  (permittedRolesIds?: number[], userData?: UserData, application?: Application): FieldDisablementReason | undefined => 
   isUserHasPermission(permittedRolesIds, userData, application) ? undefined : FieldDisablementReason.NO_PERMISSIONS_TO_EDIT;

export const hasEditableFields = (config?: Phase | undefined, userData?: UserData, application?: Application) => config?.checkGroups?.some(group => group.checks?.some(check =>
  isUserHasPermission(check.roleIds, userData, application))) || false;

