import React, {ReactElement, useContext, useState} from 'react';
import {CBSLoanDetails} from '../../../../../../../shared/model/cbs-loans';
import {TableBody} from '@material-ui/core';
import ConsolidatedLoansTableRow from './ConsolidatedLoansTableRow';
import ConsolidatedLoansTableFooter from './ConsolidatedLoansTableFooter';
import usePost from '../../../../../../../shared/hooks/use-post.hook';
import {ApiHelper} from '../../../../../../../utils/api-helper';
import {useLocation} from 'react-router';
import {RoutePaths} from '../../../../../../../routes/routes.paths';
import {AppSnackbarContext} from '../../../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import {Trans} from 'react-i18next';
import { LoanConfigurationContext } from '../../../../../../loan-configurations/loan-configuration/LoanConfiguration';
import { LoanApplicationNavigationContext } from '../../../../navigation/loan-navigation.model';

const TRANS_PREFIX = 'LOAN_CONFIGURATIONS.LOAN_APPLICATION_DATA.CONSOLIDATED_LOANS';

interface Props {
  cbsLoans: CBSLoanDetails[];
  refetchCbsLoans: () => void;
}

const ConsolidatedLoansTableBody = ({cbsLoans, refetchCbsLoans}: Props): ReactElement => {

  const {showSuccessMessage} = useContext(AppSnackbarContext);
  const {setApplicationChanged} = useContext(LoanApplicationNavigationContext);
  const currentPath = useLocation().pathname;
  const releaseProduct = usePost<void, {productId: number}>(ApiHelper.getReleaseProductUrl());
  const [loading, setLoading] = useState<boolean>(false);
  const isLoanApplicationDataPath = currentPath.includes(RoutePaths.LOAN_APPLICATION_DATA);

  const onReleaseProduct = (productId?: number): void => {
    setLoading(true);
    if (productId) {
      releaseProduct({productId})
        .then(() => {
          refetchCbsLoans();
          setApplicationChanged(true);
          showSuccessMessage(
            <Trans values={{productId: productId}}>
              {`${TRANS_PREFIX}.PRODUCT_RELEASE_SUCCESS`}
            </Trans>);
          setLoading(false);
        });
    }
  };

  return (
    <TableBody>
      {
        cbsLoans.map((loan, index) =>
          <ConsolidatedLoansTableRow key={loan.loanId}
                                     cbsLoanDetails={loan}
                                     rowNumber={index + 1}
                                     hasActionButton
                                     showReleaseButton={isLoanApplicationDataPath}
                                     onReleaseProduct={onReleaseProduct}
                                     isLoading={loading} />)
      }
      <ConsolidatedLoansTableFooter />
    </TableBody>
  );
};

export default ConsolidatedLoansTableBody;
